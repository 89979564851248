import { render, staticRenderFns } from "./ExerciseResult.vue?vue&type=template&id=92cf4ed0&scoped=true&"
import script from "./ExerciseResult.vue?vue&type=script&lang=ts&"
export * from "./ExerciseResult.vue?vue&type=script&lang=ts&"
import style0 from "./ExerciseResult.scss?vue&type=style&index=0&id=92cf4ed0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92cf4ed0",
  null
  
)

export default component.exports