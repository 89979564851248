



































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import { Route } from 'vue-router';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import WrapperContent from '@/components/WrapperContent/WrapperContent.vue';
import VideoHelpResolution from '@/components/Exercise/HelpResolution/VideoHelpResolution/index.vue';
import QuestionHelpResolution from '@/components/Exercise/HelpResolution/QuestionHelpResolution/index.vue';
import MapQuestions from '@/components/Exercise/MapQuestions/MapQuestions.vue';
import MapQuestionLoading from '@/components/Exercise/MapQuestions/MapQuestionLoading.vue';
import Question from '@/components/Exercise/Question/Question.vue';
import QuestionLoading from '@/components/Exercise/Question/QuestionLoading.vue';
import OptionsContent from '@/components/OptionsContent/OptionsContent.vue';
import Footer from '@/components/Footer/Footer.vue';

import BarChart from './components/Graphics/BarChart.vue';
import BarChartLoading from './components/Graphics/BarChartLoading.vue';
import ModalExerciseCompletedScore from './components/ModalExerciseCompletedScore/index.vue';

import Arrow from '@/assets/icons/Arrow.vue';

import ExerciseService from '@/services/Exercise/ExerciseService';
import BookService from '@/services/Book/BookService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { Book } from '@/globalInterfaces/Book';
import { QuestionExercise, OptionQuestion } from './interface';

const ROUTE_NAME_EXERCISE_QUESTIONS = 'ExerciseQuestions';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    WrapperContent,
    OptionsContent,
    MapQuestions,
    MapQuestionLoading,
    Question,
    QuestionLoading,
    Arrow,
    VideoHelpResolution,
    QuestionHelpResolution,
    Footer,
    BarChart,
    BarChartLoading,
    ModalExerciseCompletedScore
  }
})
export default class ExerciseResult extends Vue {
  private isAnimation = false;
  private isLoading = true;
  private showModalExerciseCompletedScore = false;
  private index = 0;
  private percent = 0;
  private keyLoad = 0;

  private exerciseResultsService: Record<string, any> = {};
  private exerciseResults: QuestionExercise[] | [] = [];

  private ExerciseService = new ExerciseService();
  private BookService = new BookService();
  private setModal = new ActiveModal();

  async mounted() {
    this.setBreadCrumbs();
    this.setResult();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get routeParams() {
    const {
      context, topicID, fileID, path, slugFile 
    } = this.$route.params;

    return {
      context,
      topicID: Number(topicID),
      fileID: Number(fileID),
      path,
      slugFile
    };
  }

  beforeRouteEnter(to: Route, from: Route, next: (cb: (vm: ExerciseResult) => void) => void): void {
    next((vm: ExerciseResult) => {
      const previousRouteName = from.name;

      if (previousRouteName === ROUTE_NAME_EXERCISE_QUESTIONS) {
        // eslint-disable-next-line no-param-reassign
        vm.showModalExerciseCompletedScore = true;
      }
    });
  }

  @Watch('showModalExerciseCompletedScore')
  setModalExerciseCompletedScore() {
    if (!this.showModalExerciseCompletedScore) return;

    this.setModal.activeModal('ModalExerciseCompletedScore');
  }

  async setResult() {
    try {
      this.isLoading = true;
      const { fileID } = this.routeParams;

      this.exerciseResultsService = await this.ExerciseService.getExercise(fileID);

      await this.getExerciseResults();

      this.setPerformance();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  validQuestionSkiped(id: any, answer: any) {
    let type = 'skip';

    answer.forEach((data: any) => {
      if (data.questionID === id) type = 'answered';
    });

    return type;
  }

  validQuestionAnswer(questionId: any, id: any, answer: any) {
    let idAnswer = '';

    answer.forEach((data: any) => {
      if (data.questionID === questionId) idAnswer = data.optionID;
    });

    return idAnswer;
  }

  async getExerciseResults() {
    try {
      const exercise = this.exerciseResultsService;
      const { fileID } = this.routeParams;

      const exerciseResponse = await this.ExerciseService.getExerciseByUser(fileID);

      exercise.questions.forEach((question: any, indexQuestion: number) => {
        const questionId = question.id;
        question.options.forEach((questionOption: any, index: number) => {
          const answer = this.validQuestionAnswer(
            questionId,
            questionOption.idOptions,
            exerciseResponse.answer
          );

          exercise.questions[indexQuestion].options[index] = {
            ...questionOption,
            value: questionOption.idOptions === answer,
            answerOptionID: answer
          };
        });

        exercise.questions[indexQuestion] = {
          ...question,
          response: this.validQuestionSkiped(questionId, exerciseResponse.answer)
        };
      });

      this.exerciseResults = exercise.questions;
      this.keyLoad += 1;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do resultado.',
        status: 'error'
      });

      console.error(error);
    }
  }

  textResolution(index: number) {
    const exerciceResult = this.exerciseResults;

    return exerciceResult?.length
      && 'resolution' in exerciceResult[index]
      && exerciceResult[index]?.resolution.replace(/<\/?[^>]+(>|$)/g, '') !== ''
      ? exerciceResult[index].resolution
      : '';
  }

  getQuestion(index: number): QuestionExercise | {} {
    return this.exerciseResults?.length ? this.exerciseResults[index] : {};
  }

  setPerformance() {
    let hits = 0;

    if (this.exerciseResults?.length) {
      this.exerciseResults.forEach((question: QuestionExercise) => {
        question.options.forEach((option: OptionQuestion) => {
          if (option.correct === 1 && option.value === true) {
            hits += 1;
          }
        });
      });

      this.percent = Number(((hits / this.exerciseResults.length) * 100).toFixed(1));
    }
  }

  mapNavQuestion(value: number): void {
    if (this.index === value) {
      return;
    }
    this.setAnimation();
    setTimeout(() => {
      this.index = value;
    }, 500);
  }

  setAnimation() {
    this.isAnimation = true;
    setTimeout(() => {
      this.isAnimation = false;
    }, 1200);
  }

  async setBreadCrumbs() {
    const { context, path } = this.routeParams;

    if (context === 'planner') {
      this.setBreadCrumbsPlanner();
      return;
    }

    if (context === 'cursos') {
      this.setBreadCrumbsCourse();
      return;
    }

    const bookTitle = await this.getBookTitle();

    this.$breadcrumb.set([
      { title: 'cadernos', to: '/cadernos' },
      { title: bookTitle, to: `/caderno/${path}` },
      { title: 'exercício', to: null },
      { title: 'resultado', to: null }
    ]);
  }

  async getBookTitle() {
    const { path } = this.$route.params;
    const allBooksStore: Book[] = this.$store.getters.allBooks;

    const bookFromStore = this.findBookSlug(allBooksStore, path);

    if (bookFromStore) return bookFromStore?.title;

    const allBooksService: Book[] = await this.BookService.books(
      undefined,
      this.$store.getters.profile?.institution?.id
    );

    return this.findBookSlug(allBooksService, path)?.title;
  }

  findBookSlug(books: Book[], slug: string) {
    return books.find((book: Book) => book.slug === slug);
  }

  setBreadCrumbsPlanner() {
    this.$breadcrumb.set([
      { title: 'planner', to: '/planner' },
      { title: 'exercício', to: null },
      { title: 'resultado', to: null }
    ]);
  }

  setBreadCrumbsCourse() {
    const { path } = this.routeParams;

    const splitPath = path.split('&');
    const slugCourse = splitPath[0];
    const idCourses = splitPath[1];
    const idClass = splitPath[2];

    this.$breadcrumb.set([
      { title: 'cursos', to: `/cursos/${slugCourse}/${idCourses}/${idClass}` },
      { title: 'exercício', to: null },
      { title: 'resultado', to: null }
    ]);
  }

  setPage(): void {
    this.setTrackAmplitude('Botão - Como melhorar seu desempenho');

    const {
      context, path, topicID, fileID, slugFile 
    } = this.routeParams;

    this.$router.push({
      path: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}/resultado/desempenho`
    });
  }

  setTrackAmplitude(local: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ExerciseResult',
          local
        }
      }
    });
  }
}
