















































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import Gift from '@/assets/icons/Gift.vue';
import Stars from '@/assets/icons/Stars.vue';

import { DESCRIPTIONS } from './constants';

import { OptionQuestion, QuestionExercise } from '../../interface';

@Component({
  components: {
    Modal,
    Gift,
    Stars
  }
})
export default class ModalExerciseCompletedScore extends Vue {
  @Prop() questions!: QuestionExercise[];

  private allCorrect = false;

  get description() {
    const descriptions = this.allCorrect ? DESCRIPTIONS.allCorrect : DESCRIPTIONS.someCorrect;

    const randomIndex = Math.floor(Math.random() * descriptions.length);

    return descriptions[randomIndex];
  }

  @Watch('questions', { immediate: true, deep: true })
  validateAllQuestionsAreCorrect() {
    if (!this.questions.length) return;

    this.allCorrect = this.questions.every((question: QuestionExercise) => {
      const correctOptions = this.filterCorrectOptions(question.options);

      const incorrectOptions = this.filterIncorrectOptions(question.options);

      return correctOptions.length === 1 && incorrectOptions.length === question.options.length - 1;
    });
  }

  filterCorrectOptions(options: OptionQuestion[]) {
    if (!options.length) return [];

    return options.filter(
      (option: OptionQuestion) => option.correct === 1 && option.value === true
    );
  }

  filterIncorrectOptions(options: OptionQuestion[]) {
    if (!options.length) return [];

    return options.filter(
      (option: OptionQuestion) => option.correct === 0 && option.value === false
    );
  }
}
