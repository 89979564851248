












import { Component, Prop, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import ListButtonShare from './ListButtonsShare/ListButtonShare.vue';

@Component({
  components: {
    Modal,
    ListButtonShare
  }
})
export default class Share extends Vue {
  private url = window.location.href;
  @Prop() setActiveShare!: Function;

  mounted() {
    const $ShareModal = document.getElementById('modalShare');
    // @ts-ignore
    $ShareModal.addEventListener('hidden.bs.modal', () => {
      this.setActiveShare();
    });
  }
}
