





















import { Component, Vue, Prop } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ExLoading
  }
})
export default class WrapperContentLoading extends Vue {
  @Prop({ default: true }) showSecondElement!: boolean;
}
