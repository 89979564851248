




















































































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

import ButtonReportProblem from '@/components/ButtonReportProblem/ButtonReportProblem.vue';
import Share from '@/components/Share/Share.vue';

import Like from '@/assets/icons/Like.vue';
import Deslike from '@/assets/icons/Deslike.vue';
import Movie from '@/assets/icons/Movie.vue';
import Favorite from '@/assets/icons/Favorite.vue';
import iShare from '@/assets/icons/Share.vue';

import HistoricService from '@/services/Historic/historicService';
import { TypeMaterial } from '@/services/Historic/historicInterface';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    Like,
    Deslike,
    Movie,
    Favorite,
    iShare,
    ButtonReportProblem,
    Share
  }
})
export default class OptionsContent extends Vue {
  @Prop({ default: 'video' }) type!: TypeMaterial;
  @Prop() context!: string;
  @Prop() titleTopic!: string;
  @Prop() titleBook!: string;
  @Prop({ default: '' }) path!: string;
  @Prop() idFile!: number;
  @Prop() idTopic!: number;
  @Prop({ default: true }) showFavorite!: boolean;
  @Prop({ default: true }) showOptionsLike!: boolean;
  @Prop({ default: true }) showShare!: boolean;
  @Prop() file!: Record<string, any>;

  private historicService = new HistoricService();

  private isActiveLike = false;
  private isActiveDeslike = false;
  private isActiveMovie = false;
  private isActiveFavorite = false;
  private isActiveShare = false;

  mounted() {
    this.InitialStateFavorite();
    this.InitialValidation();
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  @Watch('file')
  async InitialStateFavorite() {
    if (!this.idFile) return;

    const data = await this.historicService.getHistoric({ type: 'video', listIds: [this.idFile] });

    if (data) {
      this.isActiveFavorite = data[0]?.list || false;
    }
  }

  @Watch('file')
  async InitialValidation() {
    if (!this.idFile) {
      this.isActiveLike = false;
      this.isActiveDeslike = false;

      return;
    }

    const data = await this.historicService.getHistoric({
      type: this.type,
      listIds: [this.idFile]
    });

    if (data[0]?.avaliation) {
      if (data[0]?.avaliation === 5) {
        this.isActiveLike = true;
      }

      if (data[0]?.avaliation === 1) {
        this.isActiveDeslike = true;
      }
    } else {
      this.isActiveLike = false;
      this.isActiveDeslike = false;
    }
  }

  setActiveShare() {
    this.isActiveShare = false;
  }

  async likeClick() {
    this.setTrackAmplitude('Gostei');

    this.isActiveLike = !this.isActiveLike;
    if (!this.isActiveLike) {
      await this.setAvaliation(0);
      return;
    }
    if (this.isActiveDeslike) {
      this.isActiveDeslike = !this.isActiveDeslike;
    }
    await this.setAvaliation(5);
  }

  async deslikeClick() {
    this.setTrackAmplitude('Não gostei');

    this.isActiveDeslike = !this.isActiveDeslike;
    if (!this.isActiveDeslike) {
      await this.setAvaliation(0);
      return;
    }
    if (this.isActiveLike) {
      this.isActiveLike = !this.isActiveLike;
    }
    await this.setAvaliation(1);
  }

  async setAvaliation(value: number) {
    const { type } = this;

    await this.historicService.saveHistoric({
      idobject: this.idFile,
      type,
      avaliation: value
    });
  }

  @Watch('$store.getters.isMovie')
  setMovieClick(): void {
    if (!this.$store.getters.isMovie && this.isActiveMovie) {
      this.isActiveMovie = !this.isActiveMovie;
    }
  }

  movieClick(): void {
    this.setTrackAmplitude('Cinema');

    this.isActiveMovie = !this.isActiveMovie;
    this.$store.commit('setIsMovie', !this.$store.getters.isMovie);
  }

  async favoriteClick() {
    this.setTrackAmplitude('Favorito');

    this.isActiveFavorite = !this.isActiveFavorite;

    await this.historicService.saveHistoric({
      idobject: this.idFile,
      type: 'video',
      list: this.isActiveFavorite,
      object: {
        idTopic: String(this.idTopic),
        thumb:
          this.file?.detail?.thumb?.length
          && this.file?.detail?.thumb[3]
          && this.file?.detail?.thumb[3].link,
        time: this.file.time,
        title: this.file.title,
        slug: this.file.slug,
        book: this.path,
        context: this.context,
        path: this.path,
        titleTopic: this.titleTopic,
        titleBook: this.titleBook
      }
    });
  }

  share() {
    this.setTrackAmplitude('Compartilhar');

    this.isActiveShare = true;
  }

  setNameTypeAmplitude(type: string) {
    if (type === 'video') return 'Vídeo';
    if (type === 'exercice') return 'Exercício';

    return 'Materiais';
  }

  setTrackAmplitude(name: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'OptionsContent',
          local: this.setNameTypeAmplitude(this.type),
          name
        }
      }
    });
  }
}
