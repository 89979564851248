













































import { Component, Prop, Vue } from 'vue-property-decorator';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';
import Lock from '@/assets/icons/Lock.vue';

import WrapperContentLoading from './WrapperContentLoading.vue';

import { DEFAULT_USERS } from '@/constant/AvatarsExp';

const INDEX_MAIN_TEACHER = 0;

@Component({
  components: {
    BoxContainer,
    Checkbox,
    Lock,
    WrapperContentLoading
  }
})
export default class WrapperContent extends Vue {
  @Prop() file!: Record<string, any>;
  @Prop() checked!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop() hideCheckbox!: boolean;
  @Prop({ default: false }) showTeacherIcon!: boolean;
  @Prop({ default: true }) hasPermission!: boolean;

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get showLoading() {
    return !this.file || !this.file.title;
  }

  setCheckbox(type: string, checked: boolean) {
    this.$emit('wrapper-checked', this.file, checked);
  }

  getTeacherName() {
    return this.file?.teachers[INDEX_MAIN_TEACHER]?.name || '';
  }

  getTeacherThumb() {
    return this.file?.teachers[INDEX_MAIN_TEACHER]?.thumb
      ? `https://explicae.com.br/app/${this.file.teachers[INDEX_MAIN_TEACHER].thumb}`
      : DEFAULT_USERS;
  }
}
